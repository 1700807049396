import { useTheme } from '@emotion/react';
import AngleRightSVG from '../../public/img/angle-right.svg';
import { PostCard } from './post-card';
import { Link } from '../styled/Link';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Action, City } from '../../models';

export function Posts({
  title,
  actions,
  currentCity,
  href,
}: {
  title: string;
  currentCity: City;
  href: string;
  actions: { results: Action[] };
}) {
  const theme = useTheme();
  return (
    <div>
      <div
        // page__actions
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          marginBottom: '4rem',
          ...theme.mixins.row(),
          [DEVICE_SCREENS_MAX_STRING.md]: {
            marginBottom: '2rem',
          },
        }}
      >
        <div
          // page__controls
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            ...theme.mixins.col(1),
          }}
        >
          <div
            // main-block-controls-v2
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 0 8px',
            }}
          >
            {href ? (
              <Link
                // main-block-controls-v2__title
                href={href}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  fontSize: '2.43rem',
                  display: 'flex',
                  color: theme.colors.black,
                  '&:hover': {
                    color: theme.colors.red,
                    textDecoration: 'underline',
                    svg: {
                      fill: theme.colors.red,
                    },
                  },
                  [DEVICE_SCREENS_MAX_STRING.sm]: {
                    fontSize: '24px',
                  },
                }}
              >
                {title}
                <div>
                  <AngleRightSVG
                    css={{
                      width: 30,
                      height: '1.5rem',
                      fill: theme.colors.black,
                      [DEVICE_SCREENS_MAX_STRING.md]: {
                        width: 30,
                        height: 15,
                      },
                    }}
                  />
                </div>
              </Link>
            ) : (
              <h3
                css={{
                  fontFamily: 'Montserrat',
                  fontWeight: '700',
                  fontSize: '2.43rem',
                  display: 'flex',
                  color: theme.colors.black,
                  [DEVICE_SCREENS_MAX_STRING.sm]: {
                    fontSize: '24px',
                  },
                }}
              >
                {title}
              </h3>
            )}
          </div>
        </div>
        <div
          // scroll-wrapper
          css={{
            width: '100%',
            [DEVICE_SCREENS_MAX_STRING.sm]: {
              width: '100%',
              overflowX: 'scroll',
              whiteSpace: 'nowrap',
              paddingTop: 7,
              '&>*': {
                whiteSpace: 'normal',
              },
              '::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            },
          }}
        >
          <div
            // actions actions_scroll
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              ...theme.mixins.flex(),
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                flexWrap: 'nowrap',
              },
            }}
          >
            {!!actions?.results.length &&
              actions.results.map((item) => (
                <PostCard
                  key={item.id}
                  item={item}
                  currentCity={currentCity}
                  scroll
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
